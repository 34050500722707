/* Copyright 2022 Dustin Barker. All rights reserved. */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

:root {
  --pie-f: #e0e0e0;
  --pie-p: yellow;
  --pie-t: #65ff00;
  --s0: #48b649;
  --s1: #bdff00;
  --s2: #ffe900;
  --s3: #ff9200;
  --s4: #ff6600;
  --s5: #ff3a00;
  --s6: #ff0f00;
  --s7: #ff0049;
  --s8: #ff0075;
  --s9: #ff00a0;
  --s10: #ff00cc;
  --mobile-font-size: 5vw;
  --mobile-digit-font-size: 6vw;
  --mobile-key-font-size: 7vw;
}

body {
  font: var(--mobile-font-size) "Helvetica", Futura, sans-serif;
  height: 100%;
  min-height: 100%;
  margin-top: 18px;
}

.mark-e {
  background-color: white;
}

.mark-f {
  background-color: var(--pie-f);
}

.mark-p {
  background-color: var(--pie-p);
}

.mark-t {
  background-color: var(--pie-t);
}

.s0 {
  background-color: var(--s0);
}

.s1 {
  background-image: linear-gradient(to right, var(--s0), var(--s1));
}

.s2 {
  background-image: linear-gradient(to right, var(--s1), var(--s2));
}

.s3 {
  background-image: linear-gradient(to right, var(--s2), var(--s3));
}

.s4 {
  background-image: linear-gradient(to right, var(--s3), var(--s4));
}

.s5 {
  background-image: linear-gradient(to right, var(--s4), var(--s5));
}

.s6 {
  background-image: linear-gradient(to right, var(--s5), var(--s6));
}

.s7 {
  background-image: linear-gradient(to right, var(--s6), var(--s7));
}

.s8 {
  background-image: linear-gradient(to right, var(--s7), var(--s8));
}

.s9 {
  background-image: linear-gradient(to right, var(--s9), var(--s10));
}

.status-button {
  font: var(--mobile-font-size) "Helvetica", Futura, sans-serif;
  border-radius: 5px;
  padding: 8px;
  color: white;
  min-width: 100px;
  text-align: center;
  border: none;
  text-decoration: none;
  margin-bottom: 8px;
}

@media screen and (min-width: 450px) {
  .status-button {
    font-size: 14px;
  }
}

.streak {
  float: left;
  font-weight: bold;
}

.status-message {
  padding: 10px;
  float: left;
  font: 4.5vw "Helvetica", Futura, sans-serif;
  font-weight: bold;
  color: #48b649;
}

.reset {
  float: right;
  background-color: #48b649;
  font: var(--mobile-font-size) "Helvetica", Futura, sans-serif;
  font-weight: bold;
}

@media screen and (min-width: 450px) {
  .status-message {
    font-size: 14px;
  }
  .reset {
    font-size: 14px;
  }
}

.game {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: auto;
}

@media screen and (min-width: 450px) {
  .game {
    max-width: 450px;
    max-height: 800px;
  }
}

.guesses {
  margin: 10px;
}

.guess {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  gap: 8px;
}

.square {
  border: 1px solid #999;
  font-size: var(--mobile-digit-font-size);
  font-weight: bold;
  padding: 0;
  text-align: center;
  flex: 1;
  border-radius: 5px;
  color: #505050;
}

@media screen and (min-width: 450px) {
  .square {
    font-size: 14px;
  }
}

.pie {
  min-width: 10vw;
  min-height: 10vw;
  height: auto;
  border-radius: 50%;
}

@media screen and (min-width: 450px) {
  .pie {
    min-width: 40px;
    min-height: 40px;
  }
}

.fff {
  background-color: var(--pie-f);
}

.ffp {
  background-image: conic-gradient(var(--pie-f), var(--pie-f), var(--pie-p));
}

.fft {
  background-image: conic-gradient(var(--pie-f), var(--pie-f), var(--pie-t));
}

.fpp {
  background-image: conic-gradient(var(--pie-f), var(--pie-p), var(--pie-p));
}

.fpt {
  background-image: conic-gradient(var(--pie-f), var(--pie-p), var(--pie-t));
}

.ftt {
  background-image: conic-gradient(var(--pie-f), var(--pie-t), var(--pie-t));
}

.ppp {
  background-image: conic-gradient(var(--pie-p), var(--pie-p), var(--pie-p));
}

.ppt {
  background-image: conic-gradient(var(--pie-p), var(--pie-p), var(--pie-t));
}

.ptt {
  background-image: conic-gradient(var(--pie-p), var(--pie-t), var(--pie-t));
}

.ttt {
  background-color: var(--pie-t);
}

.eee {
  width: 36px;
  border-radius: 50%;
  background-color: #eee;
  stroke: black;
}

.keyboard {
  padding-bottom: 10px;
}

.digits {
  display: grid;
  gap: 1vh;
  grid-template-columns: repeat(3, 1fr);
  flex-flow: row wrap;
  justify-content: space-evenly;
  padding: 0px;
  margin: 0;
  list-style: none;
}

.key {
  background: #e0e0e0;
  padding: 1.5vh;
  color: #505050;
  font-weight: bold;
  font-size: var(--mobile-key-font-size);
  margin: 0px;
  text-align: center;
  border-radius: 5px;
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 450px) {
  .key {
    font-size: 24px;
  }
}

.spacer {
  height: 100%;
  width: 100%;
  background-color: red;
  flex: 1;
  min-height: 100%;
}

.enter {
  font-size: 5vw;
}

@media screen and (min-width: 450px) {
  .enter {
    font-size: 24px;
  }
}



